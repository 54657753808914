import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";


const RecurringDeposit = () => {
  const [amount, setAmount] = useState(100000);
  const [intrest, setIntrest] = useState(1);
  const [year, setYear] = useState(12);
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [getValue, setGetValue] = useState("");

  const [chart1, setChart1] = useState(25);
  const [chart2, setChart2] = useState(18);

  const series = [chart1, chart2];
  const options = { labels: ["Total of Payment", "Total Interest Payable"] };

  const periodOfMonth = ((amount * intrest) / 100 / 12) * year;

  const intrestRate = intrest / 100;

  useEffect(() => {
    axios
      .post(
        `https://www.tripuragraminbank.org/api/rd.php/api/calculateFutureValue?rate=${intrestRate}&nper=${year}&pmt=${amount}`
      )
      .then((res) =>
        // console.log(res.data))
        setGetValue(res.data?.Maturity_Value)
      )
      .catch((err) => {
        // console.log(err)
      });
  }, [intrestRate, year, amount]);

  const calculateEMI = () => {
    setChart2(80);
    const r = intrest / 1200;
    const n = year;
    const emi = (amount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    const totalInterest = emi * n - amount;
    const totalPayment = emi * n;
    setChart2(totalInterest);
    setChart1(totalPayment);

    setEmi(emi.toFixed(2));
    setTotalInterest(totalInterest.toFixed(2));
    setTotalPayment(totalPayment.toFixed(2));
    setChartData(
      [...Array(n).keys()].map((month) => ({
        month: month + 1,
        interest: ((amount - emi * month) * intrest) / (12 * 100),
        principal: emi - ((amount - emi * month) * intrest) / (12 * 100),
        total: emi * (month + 1),
      }))
    );
  };
  useEffect(() => {
    calculateEMI();
  }, [amount, year, intrest]);

  return (
    <div class="p-0 row m-0 align-items-center ">
      <div class="col-md-6 col-12 text-start px-sm-4 py-2"
        style={{ height: "auto" }}
      >
        <div class='col-11 m-auto bg-white p-3 rounded-3' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>

          <label class="form-label mt-2 fw-bold">Recurring Deposit (RD)</label>
          <select
            class="form-select form-select-lg mb-3"
            aria-label=".form-select-lg example"
          >
            <option selected>Are you a Senior Citizen</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>

          <label class="form-label mt-2">Amount</label>
          <div class="input-group">
            <span class="input-group-text">@</span>
            <input
              type="text"
              class="form-control"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <br />

          <input
            type="range"
            min="100000"
            max="10000000"
            step="100000"
            value={amount}
            style={{ width: "100%" }}
            onChange={(e) => setAmount(e.target.value)}
          />

          <br />

          <label class="form-label">Interest Rate</label>
          <div class="input-group">
            <span class="input-group-text ">%</span>
            <input
              type="text"
              class="form-control"
              value={intrest}
              onChange={(e) => setIntrest(e.target.value)}
            />
          </div>

          <br />

          <input
            type="range"
            min="0"
            max="15"
            step=".5"
            class=""
            value={intrest}
            style={{ width: "100%" }}
            onChange={(e) => setIntrest(e.target.value)}
          />

          <br />

          <label class="form-label mt-4"> Period In (Month)</label>
          <div class="input-group my-1">
            <span class="input-group-text">Time</span>
            <input
              type="text"
              class="form-control"
              value={year}
              onChange={(e) => setYear(e.target.value)}
            />
          </div>
          <br />
          <input
            type="range"
            min="0"
            max="120"
            step="1"
            class=""
            value={year}
            style={{ width: "100%" }}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>
      </div>

      {/*------  */}

      <div class="col-md-6 col-12 row m-0 p-0   ">
        <div class='col-11 m-auto bg-white p-3 rounded-3 h-auto' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
          <div class="p-0" >
            <div class=" p-3   d-grid align-items-center">
              <p class=" fs-1 fw-bold">₹ {amount}</p>
              <p class="text-secondary fw-semibold m-0">Total Deposit Amount</p>
            </div>
          </div>
        </div>

        <div class='col-11 m-auto bg-white p-3 rounded-3 h-auto mt-4' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
          <div class="p-0" >
            <div class=" p-3   d-grid align-items-center">
              <p class=" fs-1 fw-bold">{intrest} %</p>
              <p class="text-secondary fw-semibold m-0"> Rate Of Interest</p>
            </div>

          </div>
        </div>

        <div class='col-11 m-auto bg-white p-3 rounded-3 h-auto mt-4' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
          <div class="p-0" >
            <div class=" p-3 d-grid align-items-center">
              <p class=" fs-1 fw-bold">₹ {getValue}</p>
              <p class="text-secondary fw-semibold m-0">
                {" "}
                Maturity Value (Approx.)
              </p>
            </div>
          </div>
        </div>




      </div>
    </div>
  );
};

export default RecurringDeposit;

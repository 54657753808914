import axios from 'axios';
import md5 from 'md5';
import React, { useState } from 'react'
import { Table, Pagination } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { useLanguage } from './LanguageContext';
import { API_BASE_URL } from './config';



const InoperativeAccounts = () => {

  const [details, setDetails] = useState()
  const [table, setTable] = useState()
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [cap, setCap] = useState(false)

  const InoperativeAccountsDetail = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const date = new Date()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  const dateFomate = formatDate(date)
  const key = 'KDCC'
  const token = md5(dateFomate + key)
  const InoperativeDetails = () => {
    axios
      .post(`${API_BASE_URL}/inoperativeAccountsList`, details, { headers: { 'X-Custom-Token': token } })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res.data.message}`,
          });
          setTable(res.data.data);
        }
      })
      .catch((res) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${res.data.message}`,
        });
      });
  };


  const tBodyData = table?.tBody;
  const tHeadData = table?.tHead;

  const tBodyyy = tBodyData?.filter((el) => {
    if (search == "") {
      return el;
    } else if (el.name.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.city.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.state.toLowerCase().includes(search.toLowerCase())) {
      return el;
    } else if (el.address.toLowerCase().includes(search.toLowerCase())) {
      return el;
    }
  });

  const totalPages = Math.ceil(tBodyyy?.length / 10);
  const indexOfLastItem = currentPage * 10;
  const indexOfFirstItem = indexOfLastItem - 10;
  const currentItems = tBodyyy?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const tBody = currentItems?.map((el, i) => {
    return (
      <tr>
        <td>
          <p class=" m-0">{el?.sr_no}</p>
        </td>
        <td>
          <p class="m-0">{el?.name}</p>
        </td>
        <td>
          <p class="m-0">{el?.address}</p>
        </td>
        <td>
          <p class="m-0">{el?.city}</p>
        </td>
        <td>
          <p class="m-0">{el?.state}</p>
        </td>
      </tr>
    );
  });

  // live link https://kdccbank.in/ && https://kdcc-bank.hilabsolution.in/
  const siteKey = '6LeofSAqAAAAAChOgAZva-s0HDaUincJCF5GkVul'
  const secretKey = '6LeofSAqAAAAAOERF6h9gTtWVMbZuufgOF_K1Wem'

  // forlocal host 3000
  // const siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
  // const secretKey = '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe'




  function onCheck() {
    setCap(true)
  }

  const { selectedLanguage } = useLanguage();


  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>{selectedLanguage == "en" ? "Inoperative Accounts" : "નિષ્ક્રિય ખાતાઓ"}</title>
        <meta name="description" content={selectedLanguage == "en" ? "Inoperative Accounts" : "નિષ્ક્રિય ખાતાઓ"} />
        <meta name="keywords" content={selectedLanguage == "en" ? "Inoperative Accounts" : "નિષ્ક્રિય ખાતાઓ"} />
      </Helmet>
      <div style={{ background: '#ffefe6' }}>
        <br />
        <div>
          <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}> {selectedLanguage == "en" ? "Inoperative Accounts" : "નિષ્ક્રિય ખાતાઓ"}</p>
        </div>
        <br />
      </div>

      <div class='col-lg-6 col-md-8 col-11 m-auto border p-3 text-start my-5 rounded-2'>
        <p>{selectedLanguage == "en" ? "Please enter search text (Account Name and Address)" : "કૃપા કરીને શોધ ટેક્સ્ટ દાખલ કરો (એકાઉન્ટનું નામ અને સરનામું)"}</p>

        <div class='row col-11 m-auto mt-3'>
          <div class='col-md-6'>
            <p class='text-black'>{selectedLanguage == "en" ? "Name" : "નામ"} <span class='text-danger'>*</span></p>
          </div>
          <div class='col-md-6'>
            <input type="text" class='form-control' name='Name' value={details?.Name} onChange={InoperativeAccountsDetail} />
          </div>
        </div>

        <div class='row col-11 m-auto mt-3'>
          <div class='col-md-6'>
            <p class='text-black'>{selectedLanguage == "en" ? "Address/ City Name (Optional)" : "સરનામું/શહેરનું નામ (વૈકલ્પિક)"}  </p>
          </div>
          <div class='col-md-6'>
            <input type="text" class='form-control' name='Address' value={details?.Address} onChange={InoperativeAccountsDetail} />
          </div>
        </div>


        <div class='row m-auto mt-4 '>
          <div class=''>
            <div style={{ height: 'auto' }}>
              <ReCAPTCHA style={{ width: '100%' }} sitekey={siteKey} onChange={onCheck} />
            </div>
          </div>
        </div>
        <div class='d-flex my-4'>
          <button class='btn btn-blue m-auto px-4' disabled={cap ? false : true} onClick={InoperativeDetails}>{selectedLanguage == "en" ? "Go" : "જાઓ"}</button>
        </div>
      </div>

      {tBodyData && tBodyData.length > 0 ?
        <div class="text-start col-11 m-auto">
          <input
            type="text"
            class="form-control"
            placeholder="Search here "
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div> : ""
      }


      <div
        class="mt-4 col-11 m-auto"
        style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>

              {tHeadData?.map((el) => {
                return (
                  <th
                    style={{ background: "#BB355A" }}
                    class="text-center text-white"
                  >
                    <p class=" m-0 text-nowrap">{el.headName}</p>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{tBody}</tbody>
        </Table>
        <div>
          <Pagination>
            {Array.from({ length: totalPages }, (_, i) => (
              <Pagination.Item
                key={i}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
    </div>
  )
}

export default InoperativeAccounts
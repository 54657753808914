import React, { useEffect, useState } from 'react'
import './gallery.css'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import md5 from 'md5'
import { useLanguage } from './LanguageContext'
import { API_BASE_URL } from './config'

// const gallaryData = [
//     {
//         year: '2015',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2015-ABCD',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Prakash',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Akash',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Vinit',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Prafull',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Jay',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Oom',
//             },
//         ]
//     },
//     {
//         year: '2015',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2015-EEGH',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
//     {
//         year: '2015',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2015-IJKL',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
//     {
//         year: '2016',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2016-ABCD',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
//     {
//         year: '2016',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2016-EEGH',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
//     {
//         year: '2016',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2016-IJKL',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
//     {
//         year: '2020',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2020-ABCD',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
//     {
//         year: '2020',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2020-EEGH',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
//     {
//         year: '2020',
//         img: `${require('./images/famly.jpg')}`,
//         tite: '2020-IJKL',
//         desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
//         albumData: [
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//             {
//                 img: `${require('./images/famly.jpg')}`,
//                 tite: 'Abcd',
//             },
//         ]
//     },
// ]

const Gallery = () => {

    const [gallaryData, setGalleryData] = useState()
    const [galleryButton, setGalleryButton] = useState()
    const [galleryFilter, setGalleryFilter] = useState()
    const [gallery, setGallery] = useState()
    const [albums, setAlbums] = useState('')
    const [currentAlbumIndex, setCurrentAlbumIndex] = useState(0)
    const { selectedLanguage } = useLanguage();

    const date = new Date()
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    }

    const dateFomate = formatDate(date)
    const key = 'KDCC'
    const token = md5(dateFomate + key)

    useEffect(() => {
        axios.post(`${API_BASE_URL}/gallery`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
            .then((res) => {
                setGalleryData(res?.data?.data)
            })
            .catch((err) => {
            })
    }, [selectedLanguage, token])


    useEffect(() => {
        const galleryButton = gallaryData?.map((el) => el.year)
        const uniqueSetButton = new Set(galleryButton);
        setGalleryButton(Array.from(uniqueSetButton));
    }, [gallaryData])

    useEffect(() => {
        setGalleryFilter("All")
    }, [galleryButton])

    const showNextImage = () => {
        setCurrentAlbumIndex((prevIndex) => (prevIndex + 1) % albums.length)
    }

    const showPreviousImage = () => {
        setCurrentAlbumIndex((prevIndex) => (prevIndex - 1 + albums.length) % albums.length)
    }


    useEffect(() => {
        if (galleryFilter == "All") {
            setGallery(gallaryData)
        } else {
            setGallery(gallaryData?.filter((el) => el.year == galleryFilter))
        }
    }, [gallaryData, galleryFilter])


    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <title>{selectedLanguage == "en" ? "Gallery" : "ગેલેરી"}</title>
                <meta name="description" content={selectedLanguage == "en" ? "Gallery" : "ગેલેરી"} />
                <meta name="keywords" content={selectedLanguage == "en" ? "Gallery" : "ગેલેરી"} />
            </Helmet>
            <div style={{ background: '#ffefe6' }}>
                <br />
                <div>
                    <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}> {selectedLanguage == "en" ? "Gallery" : "ગેલેરી"}</p>
                </div>
                <br />
            </div>

            <div class='d-flex align-items-center col-11 m-auto gap-3 my-4'>
                <button class={galleryFilter == "All" ? 'btn btn-dark px-3' : 'btn btn-outline-dark px-3'} onClick={() => setGalleryFilter('All')}>All</button>

                {
                    galleryButton?.map((el) => {
                        return (
                            <button class={galleryFilter == el ? 'btn btn-dark px-3' : 'btn btn-outline-dark px-3'} onClick={() => setGalleryFilter(el)}>{el}</button>
                        )
                    })
                }
            </div>

            <div class='col-11 m-auto row align-items-center justify-content-center '>
                {
                    gallery?.map((el) => {
                        return (

                            <div class='col-xl-3 col-lg-4 col-sm-6 mt-4 calleryCart' onClick={() => setAlbums(el?.albumData)}  >
                                <div class='col-11 m-auto'>
                                    <div>
                                        <img src={el?.img} class='calleryImage img-fluid' style={{ objectFit: 'cover' }} alt="" />
                                    </div>
                                    <div class='my-2'>
                                        <p class='ts-2 fw-semibold' style={{ color: '#FD5900' }}>{el.title}</p>
                                        <p class='ts-1' style={{ color: '#1A2B71' }}>{el.desc}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {
                albums?.length > 0 &&
                <div className='albums'>
                    <div>
                        <br />
                        <div className='col-11 m-auto d-flex align-items-center justify-content-end my-2'>
                            <p className='text-white fs-3' onClick={() => setAlbums([])} style={{ cursor: 'pointer' }}><i className="bi bi-x-circle"></i></p>
                        </div>
                        <div className='col-11 row m-auto'>
                            <div className='col-1 d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }} onClick={showPreviousImage}><i class="bi bi-caret-left-fill fs-4 text-white"></i></div>
                            <div className='col-10 d-flex align-items-center justify-content-center'>
                                <div className='col-11'>
                                    <img src={albums[currentAlbumIndex].img} className='img-fluid' alt="" />
                                    <p className='text-center text-white mt-4 fw-semibold'>{albums[currentAlbumIndex].name}</p>
                                </div>
                            </div>
                            <div className='col-1 d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }} onClick={showNextImage}><i class="bi bi-caret-right-fill fs-4 text-white"></i></div>
                        </div>
                    </div>
                </div>
            }


        </div>
    )
}

export default Gallery
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { Helmet } from "react-helmet";
import md5 from "md5";
import { useLanguage } from "./LanguageContext";
import { API_BASE_URL } from "./config";

const ETender = (lang) => {
    const [formData, setFormData] = useState({});
    const { selectedLanguage } = useLanguage();



    const date = new Date()
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    }

    const dateFomate = formatDate(date)
    const key = 'KDCC'
    const token = md5(dateFomate + key)

    useEffect(() => {
        axios.post(`${API_BASE_URL}/etender`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
            .then((res) => {
                setFormData(res?.data?.data)
            })
            .catch((err) => {
            })
    }, [selectedLanguage, token])

    const showData = () => {
        let arr = [];
        for (let i = 0; i < formData?.length; i++) {
            arr.push(
                <>
                    <div
                        class="border m-auto d-flex align-items-center gap-2"
                        data-bs-toggle="collapse"
                        href={`#${formData?.[i].title.split(" ").join("").split("/").join("")}`}
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        style={{ background: '#1A2B71', }}
                    >
                        <div class='text-start p-3 px-sm-4  row align-items-center' >
                            <p class="m-0 fw-bold text-white">{formData?.[i].title} <span><i class="bi bi-caret-right-fill"></i></span></p>
                        </div>
                    </div>
                    <div
                        class="collapse m-auto"
                        id={formData?.[i]?.title.split(" ").join("").split("/").join("")}
                        style={{ transition: "1000ms" }}
                    >
                        <div class="card card-body border-0  ">
                            {formData[i].ETenderDocument.map((el) => {
                                return (
                                    <div class='row align-items-center  shadow mt-3 p-2 py-3'>
                                        <div class='col-lg-10 col-md-8 col-12 text-start'>
                                            <p class="p-3 m-0" style={{ fontWeight: "500" }}>
                                                {el.name}
                                            </p>
                                        </div>
                                        <div class='col-lg-2 col-md-4 col-12 text-start'>
                                            <div class='col-11 m-auto'>
                                                <a class="btn-orang" href={`${el.doc}`} target="_blanck" download >  Download PDF </a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            );
        }
        return arr;
    };

    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <title>{selectedLanguage == "en" ? "E-Tender" : "ઇ-ટેન્ડર"}</title>
                <meta name="description" content={selectedLanguage == "en" ? "E-Tender" : "ઇ-ટેન્ડર"} />
                <meta name="keywords" content={selectedLanguage == "en" ? "E-Tender" : "ઇ-ટેન્ડર"} />
            </Helmet>
            <div style={{ background: '#ffefe6' }}>
                <br />
                <div>
                    <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}> {selectedLanguage == "en" ? "E-Tender" : "ઇ-ટેન્ડર"}</p>
                </div>
                <br />
            </div>


            <div class="downloadForm2 p-4">{showData()}</div>
        </div>
    );
};

export default ETender;

import React, { useEffect, useRef, useState } from 'react'
import { useLanguage } from './LanguageContext';
import { HomeMultipalWay, HomeTop } from './ReactMultyCarousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';
import axios from 'axios';
import md5 from 'md5';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config';

const Home = () => {
  const { selectedLanguage } = useLanguage();
  const [homeData, setHomeData] = useState()

  const containerRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [bankingOption, setBankingOption] = useState(homeData?.QuickLinks?.[0]?.name)
  const [bankingFilertCart, setBankingFilterCart] = useState()
  const [counterOn, setCounterOn] = useState(false)
  const navigate = useNavigate()


  const newsLins = homeData?.Announcement?.detail


  const date = new Date()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  const dateFomate = formatDate(date)
  const key = 'KDCC'
  const token = md5(dateFomate + key)

  useEffect(() => {
    axios.post(`${API_BASE_URL}/homepage`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
      .then((res) => {
        setHomeData(res?.data?.data)
      })
      .catch((err) => {
      })
  }, [selectedLanguage, token])

  // elements
  const homeTopSliderLarge = () => {
    let arr = []
    for (let i = 0; i < homeData?.Slider?.length; i++) {
      arr.push(
        <a class='HomeSlider' href={homeData?.Slider?.[i]?.url} target="_blanck"  >
          <img src={homeData?.Slider?.[i].limage} style={{ width: '100%', height: '100%' }} alt="" />
        </a>
      )
    }
    return arr;
  }
  const homeTopSliderSmall = () => {
    let arr = []
    for (let i = 0; i < homeData?.Slider?.length; i++) {
      arr.push(
        <a class='HomeSlider' href={homeData?.Slider?.[i]?.url} target="_blanck">
          <img src={homeData?.Slider?.[i].simage} style={{ width: '100%', height: '100%' }} alt="" />
        </a>
      )
    }
    return arr;
  }


  const homeMaltipalWay = () => {
    let arr = []
    for (let i = 0; i < homeData?.HomeMakeUsUniqueSlider?.length; i++) {
      arr.push(
        <div class='maltipalWay ' data-aos="fade-in-up" style={{ border: 'none' }}>
          <img src={homeData?.HomeMakeUsUniqueSlider?.[i].image} style={{ width: '100%', height: '100%', objectFit: 'cover', }} alt="" />
          <div class='maltipalWayText p-2'  >
            <div class='col-11 m-auto'>
              <p class='ts-3 fw-bold mt-xl-2 mt-3 ' dangerouslySetInnerHTML={{ __html: homeData?.HomeMakeUsUniqueSlider?.[i]?.title }}></p>
              <p class='ts-2 lh-sm text-start maltipalWayTextL' dangerouslySetInnerHTML={{ __html: homeData?.HomeMakeUsUniqueSlider?.[i]?.text1 }}></p>
              <p class='ts-2 lh-sm text-start maltipalWayTextL' dangerouslySetInnerHTML={{ __html: homeData?.HomeMakeUsUniqueSlider?.[i]?.text2 }}></p>
              <div class='d-flex gap-3 my-3 '>
                <button class={`${homeData?.HomeMakeUsUniqueSlider?.[i]?.btn_css} maltipalWayTextL`} onClick={() => navigate(`${homeData?.HomeMakeUsUniqueSlider?.[i]?.url}`)}  >{homeData?.HomeMakeUsUniqueSlider?.[i]?.btn_nm} <i class="bi bi-arrow-right"></i></button>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return arr;
  }

  const Achievement = () => {
    let arr = []
    for (let i = 0; i < homeData?.HomeAchievement?.detail?.length; i++) {
      arr.push(
        <div class='col-lg-5 col-md-8 col-10 m-auto'>
          <img src={homeData?.HomeAchievement?.detail?.[i]?.image} onClick={() => navigate(`${homeData?.HomeAchievement?.detail?.[i]?.url}`)} style={{ width: '100%' }} alt="" />
          <p class='my-3  ts-3' dangerouslySetInnerHTML={{ __html: homeData?.HomeAchievement?.detail?.[i]?.desc }}></p>
        </div>
      )
    }
    return arr
  }

  // process
  useEffect(() => {
    setBankingOption(homeData?.QuickLinks?.[0]?.name)
  }, [homeData])

  useEffect(() => {
    setBankingFilterCart(homeData?.QuickLinks?.filter((el) => el.name == bankingOption))
  }, [bankingOption])

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, [])

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = x - startX;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setScrollLeft(containerRef.current.scrollLeft);
  };
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>{homeData?.GeneralSetting?.pagetitle}</title>
        <meta name="description" content={homeData?.GeneralSetting?.pagedesc} />
        <meta name="keywords" content={homeData?.GeneralSetting?.pagekeyword} />
      </Helmet>
      <div class='homeWindowSlider'>
        <HomeTop data={homeTopSliderLarge()} />
      </div>
      <div class='homeMobileSlider'>
        <HomeTop data={homeTopSliderSmall()} />
      </div>
      <div class='col-xxl-10 col-sm-11 m-auto p-sm-2'>

        <div class='mx-sm-3 bg-white border border-sm-3   d-flex gap-5 justify-content-around' style={{ borderRadius: '50px 50px 0 0', position: 'relative', zIndex: '1', top: "-50px" }}>
          <div class='col-sm-10 col-sm-8 col-10  m-auto d-flex gap-sm-5 gap-4 justify-content-xl-around ' style={{ overflowX: 'auto', cursor: 'pointer' }} ref={containerRef} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
            {homeData?.QuickLinks?.map((el) => {
              return (
                <div class='mt-3' style={bankingOption == el.name ? { borderBottom: '3px solid #FD5900', width: 'fit-content' } : { borderBottom: '3px solid white', width: 'fit-content' }} onClick={() => setBankingOption(el.name)} >
                  <div class='d-flex align-items-center gap-2 my-xxl-3 my-2'>
                    <div class='m-auto d-flex align-items-center justify-content-center homeSubmenuIcon  '  >
                      <img src={el.img_url} alt="" />
                    </div>
                    <div>
                      <p class='fw-semibold my-2 text-nowrap ts-2' style={bankingOption == el.title ? { color: "#FD5900" } : { color: 'black' }} dangerouslySetInnerHTML={{ __html: el.name }}></p>
                    </div>
                  </div>

                </div>
              )
            })

            }

          </div>
        </div>
      </div>

      <div class='col-xxl-10 col-11 m-auto row  p-0'>
        {
          bankingFilertCart?.[0]?.detail?.map((el) => {
            return (
              <div class='col-lg-4 col-md-6  mb-4 mb-sm-5' data-aos="fade-up">
                <div class='col-sm-11  m-auto text-start p-2 h-100' style={{ borderRadius: '0px 30px 30px 30px', background: '#e9eaf1' }} >
                  <div class='col-11 m-auto d-flex gap-xxl-0 gap-2 align-items-center justify-content-between m-0 p-0 mt-2'>
                    <div class=''>
                      <p class='fw-bold ts-3' style={{ color: '#FD5900' }} dangerouslySetInnerHTML={{ __html: el?.title }} ></p>
                      <p class=' ts-2     lh-sm mt-2 ' style={{ color: "#1A2B71" }} dangerouslySetInnerHTML={{ __html: el.detail }}></p>
                    </div>
                    <div class=' ' style={{ width: '120px' }}>
                      <img src={el?.img_url} style={{ width: "100%" }} alt="" />
                    </div>
                  </div>
                  <div class='col-11 m-auto d-flex gap-3 align-items-center my-2 mt-3  '>
                    <button class={`${el.applybtn_css} text-nowrap my-2`} onClick={() => navigate(`${el.apply_url}`)} >{el?.applybtn_nm} <i class="bi bi-arrow-right "></i></button>
                    <button class={`${el.explorebtn_css} text-nowrap my-2`} onClick={() => navigate(`${el.explore_url}`)} >{el?.explorebtn_nm} <i class="bi bi-arrow-right "></i></button>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>

      <div class='p-2'>
        <div class='rounded-5 d-flex p-2 align-items-center' style={{ background: "#1A2B71" }}>
          <div class='p-2 rounded-5 my-1 ts-3' style={{ background: "#FD5900", }}><p class='px-1 fw-bold text-white' dangerouslySetInnerHTML={{ __html: homeData?.Announcement?.title }}></p></div>
          <div class="whatsNew_lins  marquee-container">
            <div class='marquee'>
              {newsLins?.map((el) => {
                return (
                  <>
                    <a class="text-dark " href={`${el.url}`} >
                      <span
                        class="marquee_new text-white fw-bold "
                        style={{ marginLeft: "80px", marginRight: "10px" }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 40" fill="none">
                          <path d="M20 0L22.8284 17.1716L40 20L22.8284 22.8284L20 40L17.1716 22.8284L0 20L17.1716 17.1716L20 0Z" fill="white" />
                        </svg>
                      </span>
                      <span style={{ cursor: "pointer", color: 'white' }} class='ts-2'>{el?.newslink}</span>
                    </a>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div class='p-4'>
        <div class='row align-items-center justify-content-center rounded-4 ' style={{ backgroundImage: 'linear-gradient(to right,#B0D57E,#d8e99f,#EBF5B8, #dfefa9, #bddc86 )' }}>
          <div class='col-md-7 '>
            <div class='col-11 m-auto text-start my-4'>
              <p class='ts-7  lh-sm' data-aos="fade-right" style={{ color: '#3A6200', fontWeight: '900' }} dangerouslySetInnerHTML={{ __html: homeData?.BoostYourBusiness?.desc }}></p>
              <p class='ts-3 my-3 fw-bold' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: homeData?.BoostYourBusiness?.desc2 }}></p>

              <button data-aos="fade-right" class={homeData?.BoostYourBusiness?.btn_css} onClick={() => navigate(`${homeData?.BoostYourBusiness?.url}`)}  >{homeData?.BoostYourBusiness?.btn_nm} <i class="bi bi-arrow-right"></i></button>

            </div>
          </div>
          <div class='col-md-5 '>
            <div class='col-xxl-9  m-auto row my-4'>
              {homeData?.BoostYourBusinessLinks?.map((el) => {
                return (
                  <div class='col-6  mt-4 ' data-aos="zoom-in-up">
                    <div class='  m-auto  p-2 d-flex  align-items-sm-center align-items-start justify-content-center' style={{ height: '100%', width: '100%', aspectRatio: "1/1", borderRadius: '0px 20px 20px 20px', background: 'rgba(255,255,255,0.7)' }}>
                      <div>
                        <div class='m-auto d-flex align-items-center justify-content-center my-sm-0 my-2' style={{ width: '80px', height: '80px', background: 'white', borderRadius: '50%' }}>
                          <img src={el.img_url} style={{ width: '40px' }} alt="" />
                        </div>
                        <div>
                          <p class='fw-bold mt-2 ts-2' dangerouslySetInnerHTML={{ __html: el.name }}></p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <br />
          </div>
        </div>
      </div>

      <div style={{ background: '#1A2B71' }}>
        <br />
        <div class='col-11  text-white m-auto'>
          <div class='col-lg-8 text-start'>
            <p class='ts-6 fw-bold' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: homeData?.HomeMakeUsUnique?.desc }}></p>
            <p class='ts-2' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: homeData?.HomeMakeUsUnique?.desc2 }}></p>


          </div>
          <div class='mt-4 '>
            <HomeMultipalWay data={homeMaltipalWay()} />
          </div>
          <br />
        </div>
      </div>

      <div>
        <div class='col-11 m-auto my-2'>
          <div class='d-flex align-items-center justify-content-between'>
            <div class='text-start mt-3'>
              <p class='ts-4   ' data-aos="fade-right" style={{ color: '#1A2B71', fontWeight: '900' }} dangerouslySetInnerHTML={{ __html: homeData?.HomeOurOfferings?.title }}></p>
            </div>
            <div class=' d-flex justify-content-sm-end mt-3'>

              <button data-aos="fade-right" class={homeData?.HomeOurOfferings?.btn_css} onClick={() => navigate(`${homeData?.HomeOurOfferings?.btn_url}`)} >{homeData?.HomeOurOfferings?.btn_nm} <i class="bi bi-arrow-right"></i></button>

            </div>
          </div>
          <div class='row '>
            {homeData?.HomeOurOfferings?.detail?.map((el) => {
              return (
                <div class='col-xl-6 p-2 mt-3 ' data-aos="fade-in-up">
                  <div style={{ borderRadius: '0px 20px 20px 20px', backgroundImage: 'linear-gradient(to right,#070707,#9A9DA5 )', overflow: 'hidden' }}>
                    <div class='row'>
                      <div class='col-sm-7'>
                        <div class='col-11 m-auto text-start my-4'>
                          <p class='ts-3 fw-bold text-white' dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                          <p class='ts-5 fw-bold text-white' dangerouslySetInnerHTML={{ __html: el?.text1 }}></p>
                          <p class='ts-3 fw-bold text-white' dangerouslySetInnerHTML={{ __html: el?.text2 }}></p>
                          <button class={`${el.btn_css} mt-3`} onClick={() => navigate(`${el?.url}`)} >{el?.btn_nm} <i class="bi bi-arrow-right"></i></button>
                        </div>
                      </div>
                      <div class='col-sm-5  d-flex align-items-end justify-content-center'>
                        <img src={el.image} style={{ height: '230px' }} alt="" data-aos="fade-in" /></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div class='mt-5 chairmanMassage'>
        <div class='col-11 m-auto mt-3 row align-items-end '>
          <div class='col-xl-4 col-lg-5   p-0' style={{ height: '100%' }}>
            <div class='chairmanMassageTitleMobie'>
              <p class='ts-4 fw-bold ' data-aos="fade-in" dangerouslySetInnerHTML={{ __html: homeData?.HomeMessageFromChairman?.title }}></p>
            </div>
            <div class='col-sm-10 m-auto mt-5 position-relative h-100 '>
              <img src={homeData?.HomeMessageFromChairman?.image} data-aos="fade-in" style={{ width: '100%' }} alt="" />
              <div class='chairmanMassageCover'></div>
            </div>
          </div>
          <div class='col-xl-8 col-lg-7'>
            <div class='col-11 m-auto text-start text-white '>
              <p class='ts-4 fw-bold chairmanMassageTitlePc ' data-aos="fade-in" dangerouslySetInnerHTML={{ __html: homeData?.HomeMessageFromChairman?.title }}></p>
              <p class='mt-2 ts-2 tjustify' data-aos="fade-in" dangerouslySetInnerHTML={{ __html: homeData?.HomeMessageFromChairman?.desc }}></p>
              {/* <div class='d-flex justify-content-end mt-2'>
                <div>
                  <p data-aos="fade-in " class='ts-2' dangerouslySetInnerHTML={{ __html: HomeData?.message?.regards }}></p>
                  <p data-aos="fade-in " class='ts-2' dangerouslySetInnerHTML={{ __html: HomeData?.message?.name }}></p>
                  <p data-aos="fade-in
                   " class='ts-2' dangerouslySetInnerHTML={{ __html: HomeData?.message?.post }}></p>
                </div>
              </div> */}
              <br /> <br />
            </div>
          </div>
        </div>
      </div>

      <div class='col-sm-11 m-auto'>
        <p class='ts-7 fw-bold my-4' data-aos="fade-down" style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.title }}></p>
        <div class='row align-items-center  justify-content-center   '>
          <div class='col-lg-6 row'>
            <div class='col-6 mt-2 ' data-aos="fade-left">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateLeft' >
                <div class='d-flex align-items-center justify-content-center rateIcon' >
                  <img src={homeData?.HomeInterestRate?.detail?.[0]?.image} alt="" />
                </div>
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-end  mx-2'>
                    <p class='rateTitle fw-semibold' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[0]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap'>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[0]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[0]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[0]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
              </div>
            </div>


            <div class='col-6 mt-2 ' data-aos="fade-right">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateRight' >
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-start  mx-2'>
                    <p class='rateTitle fw-semibold m' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[1]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap'>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[1]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[1]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[1]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
                <div class='d-flex align-items-center justify-content-center rateIcon'  >
                  <img src={homeData?.HomeInterestRate?.detail?.[1]?.image} alt="" />
                </div>

              </div>
            </div>

            <div class='col-6 mt-2' data-aos="fade-left">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateLeft' >
                <div class='d-flex align-items-center justify-content-center rateIcon'  >
                  <img src={homeData?.HomeInterestRate?.detail?.[2]?.image} alt="" />
                </div>
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-end  mx-2'>
                    <p class='rateTitle fw-semibold m' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[2]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap'>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[2]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[2]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[2]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-6 mt-2' data-aos="fade-right">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateRight' >
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-start  mx-2'>
                    <p class='rateTitle fw-semibold m' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[3]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap'>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[3]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[3]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[3]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
                <div class='d-flex align-items-center justify-content-center rateIcon' >
                  <img src={homeData?.HomeInterestRate?.detail?.[3]?.image} alt="" />
                </div>
              </div>
            </div>

          </div>

          <div class='col-lg-6 row'>
            <div class='col-6 mt-2' data-aos="fade-left">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateLeft' >
                <div class='d-flex align-items-center justify-content-center rateIcon'  >
                  <img src={homeData?.HomeInterestRate?.detail?.[4]?.image} alt="" />
                </div>
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-end  mx-2'>
                    <p class='rateTitle fw-semibold m' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[4]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap'>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[4]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[4]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[4]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-6 mt-2' data-aos="fade-right">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateRight' >
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-start  mx-2'>
                    <p class='rateTitle fw-semibold m' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[5]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap'>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[5]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[5]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[5]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
                <div class='d-flex align-items-center justify-content-center rateIcon' >
                  <img src={homeData?.HomeInterestRate?.detail?.[5]?.image} alt="" />
                </div>

              </div>
            </div>

            <div class='col-6 mt-2' data-aos="fade-left">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateLeft' >
                <div class='d-flex align-items-center justify-content-center rateIcon'  >
                  <img src={homeData?.HomeInterestRate?.detail?.[6]?.image} alt="" />
                </div>
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-end  mx-2'>
                    <p class='rateTitle fw-semibold m' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[6]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap'>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[6]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[6]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[6]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-6 mt-2' data-aos="fade-right">
              <div class='d-flex gap-2 p-2   justify-content-between align-items-center rateRight' >
                <div class='d-flex justify-content-end' style={{}}>
                  <div class='text-start  mx-2'>
                    <p class='rateTitle fw-semibold m' dangerouslySetInnerHTML={{ __html: homeData?.HomeInterestRate?.detail?.[7]?.loan_types }}></p>
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                      <p class='rateNumber lh-sm fw-bold text-nowrap '>  <span  >{counterOn && <CountUp start={0} end={Math.floor(homeData?.HomeInterestRate?.detail?.[7]?.rates)} delay={0} />}</span>.<span  >{counterOn && <CountUp start={0} end={(homeData?.HomeInterestRate?.detail?.[7]?.rates - Math.floor(homeData?.HomeInterestRate?.detail?.[7]?.rates)).toFixed(2).slice(2)} delay={0} />}</span><span>%</span> <span class='rateTitle'>P.A.*</span></p>
                    </ScrollTrigger>
                  </div>
                </div>
                <div class='d-flex align-items-center justify-content-center rateIcon'  >
                  <img src={homeData?.HomeInterestRate?.detail?.[7]?.image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='mt-5 d-flex align-items-center' style={{ width: '100%', height: '100vh', backgroundImage: `url(${homeData?.HomeImportantNewsNotification?.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

        <div class=' col-lg-6 col-md-8  col-11 rounded-end-4 my-5' data-aos="fade-right" style={{ background: 'rgba(29,46,66,.9)' }}>
          <br />
          <div class='col-11  mt-lg-5 mt-3 text-white' data-aos="fade-right" style={{ clipPath: 'polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)', background: '#FD5900' }}>
            <div class='col-10 m-auto p-1 text-start '>
              <p class='ts-4 fw-bold ' dangerouslySetInnerHTML={{ __html: homeData?.HomeImportantNewsNotification?.title }}>

              </p>
            </div>
          </div>
          <div class='col-11 mt-4'>
            <div class='col-10 text-start text-white m-auto fw-blod'>

              {/* {
                HomeData?.importantNews?.news?.map((el) => {
                  return (
                    <div class='mt-2 ts-2 d-flex gap-3' data-aos="fade-right"> <span><i class="bi bi-chevron-double-right"></i></span>  <span>{el}</span></div>
                  )
                })
              } */}

              <div dangerouslySetInnerHTML={{ __html: homeData?.HomeImportantNewsNotification?.desc }} >

              </div>
              <br />
              <div class='mb-lg-5 mb-3'>
                <button data-aos="fade-right" class={homeData?.HomeImportantNewsNotification?.btn_css} onClick={() => navigate(`${homeData?.HomeImportantNewsNotification?.url}`)}>{homeData?.HomeImportantNewsNotification?.btn_nm} <i class="bi bi-arrow-right"></i></button>
              </div>
            </div>
          </div>
          <br />
        </div>

      </div>

      <div class='col-11 m-auto'>
        <p class='ts-6 fw-bold mt-4 mb-3' style={{ color: "#1A2B71" }} dangerouslySetInnerHTML={{ __html: homeData?.HomeAchievement?.title }}></p>
        <HomeTop data={Achievement()} />

      </div>

      {/* <div class='row p-0 m-0 my-4 align-items-center justify-content-center'>
        {
          HomeData?.businessCard?.map((el) => {
            return (
              <div class='col-lg-6 mt-5 ' data-aos="zoom-in-up"  >
                <div class='col-11 row flex-column-reverse flex-md-row m-auto p-0 m-0  rounded-4' style={{ background: '#e9eaf1' }}>

                  <div class='col-md-6  '  >
                    <div class='col-11 m-auto text-start my-4 '>
                      <img src={el?.cardIcon} data-aos="fade-in" style={{ height: '60px' }} alt="" />
                      <p class='fs-5 my-2 fw-semibold' data-aos="fade-in">{el?.text}</p>
                      {el?.button?.map((el) => {
                        return (
                          <button data-aos="fade-in" class={el.css}> {el?.btnName}</button>
                        )
                      })}
                    </div>

                  </div>
                  <div class='col-md-6 p-0'>
                    <img data-aos="fade-in" src={el?.img} style={{ width: '100%', height: '250px' }} alt="" />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div> */}
      <br />

      <div class='row' style={{ backgroundImage: 'linear-gradient( #ffffff 20% ,#ffefe6 0% )' }}>
        <div class='col-md-6'>
          <div class='col-11'>
            <img class='mb-3' data-aos="zoom-in-right" src={homeData?.HomeDownloadOurMagazine?.image} style={{ width: '100%' }} alt="" />
          </div>
        </div>
        <div class='col-md-6'>
          <div class='d-flex align-items-end h-100  '>
            <div class='col-11 m-auto text-start '  >
              <p class='ts-7 fw-bold mb-2' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: homeData?.HomeDownloadOurMagazine?.title }}></p>

              <button data-aos="fade-right" class={homeData?.HomeDownloadOurMagazine?.btn1_css} onClick={() => navigate(`${homeData?.HomeDownloadOurMagazine?.url1}`)}   >{homeData?.HomeDownloadOurMagazine?.btn1_name} <i class="bi bi-arrow-right"></i></button>

              <p class='ts-5 fw-bold mt-2' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: homeData?.HomeDownloadOurMagazine?.desc }}></p>

              {/* <p class=' fw-semibold mb-3 ts-2' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: homeData?.HomeAchievement?.title }}></p> */}

              <button data-aos="fade-right" class={`mt-3 ${homeData?.HomeDownloadOurMagazine?.btn2_css}`} onClick={() => navigate(`${homeData?.HomeDownloadOurMagazine?.url2}`)}  >{homeData?.HomeDownloadOurMagazine?.btn2_name} <i class="bi bi-arrow-right"></i></button>


            </div>
          </div>
          <br /><br />
        </div>
      </div>

    </div>
  )
}

export default Home
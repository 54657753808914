import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import './navbar.css'
import { FaArrowRightLong } from "react-icons/fa6";
import md5 from 'md5';
import axios from 'axios';
import { API_BASE_URL } from './config';

const Navbar = () => {
  const [navData, setNavData] = useState()
  const { selectedLanguage, changeLanguage } = useLanguage();
  const [showMenu, setShowMenu] = useState({});
  const [showSubMenu, setShowSubMenu] = useState({});
  const [show, setShow] = useState({});
  const [collapse, setCollapse] = useState({
    visible: "collapse show  ",
    hidden: "collapse",
  });
  const [idToCollapse, setIdToCollapse] = useState({});
  const [collapseChild, setCollapseChild] = useState({
    visible: "collapse show  ",
    hidden: "collapse",
  });
  const [idToCollapseChild, setIdToCollapseChild] = useState({});
  const [scroll, setScroll] = useState(false)
  const [headerButton, setHeaderButton] = useState()

  useEffect(() => {
    const nav = document.querySelector('.mainNav');
    let lastScrollY = window.scrollY;
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        nav.classList.add("navColor");
        setScroll(true)
      } else {
        nav.classList.remove("navColor");
        setScroll(false)
      }
    })
  })

  const date = new Date()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }
  const dateFomate = formatDate(date)
  const key = 'KDCC'
  const token = md5(dateFomate + key)

  useEffect(() => {
    axios.post(`${API_BASE_URL}/headerdata`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
      .then((res) => {
        setHeaderButton(res?.data?.data)
      })
      .catch((err) => {
      })
  }, [selectedLanguage, token])

  useEffect(() => {
    axios.post(`${API_BASE_URL}/navbar`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
      .then((res) => {
        setNavData(res?.data?.data)
      })
      .catch((err) => {
      })
  }, [selectedLanguage, token])



  // useEffect(() => {
  //   const nav = document.querySelector(".navbarMain");
  //   let lastScrollY = window.scrollY;
  //   window.addEventListener("scroll", () => {
  //     if (lastScrollY < window.scrollY) {
  //       nav.classList.add("navbarMain--hidden");
  //     } else {
  //       nav.classList.remove("navbarMain--hidden");
  //     }
  //     lastScrollY = window.scrollY;
  //   });
  // }, []);


  const navigate = useNavigate();

  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
  };

  const moveMenuPage = (el, id) => {
    if (el !== "#" && el !== 'null') {
      navigate(el);
    }
  };

  const moveInternalPage = (url) => {
    navigate(`/${url}`);
  };

  const moveInternalPageMain = (url) => {
    navigate(`/${url}`);
  };



  const PcNavbar = () => {
    return navData?.map((navItem, i) => {
      const dropdown = navItem?.submenu?.map((el) => el.name);
      return (
        <>
          {navItem?.is_active == "1" &&
            <React.Fragment key={navItem.id}>
              <div
                className='d-flex align-items-end gap-1 p-2 fw-semibold  py-3  text-nowrap h-100  ' style={i == "0" ? { marginLeft: '50px', cursor: 'pointer' } : { cursor: 'pointer' }}
                onMouseEnter={() => setShowMenu({ [navItem.id]: true })}
                onMouseLeave={() => setShowMenu({ [navItem.id]: false })}
                onClick={() => navItem?.url !== null || "" ? moveMenuPage(`${navItem.url}`) :
                  moveInternalPageMain(
                    navItem?.name
                      ?.toLocaleLowerCase()
                      .split(" ")
                      .join("-"),
                    navItem?.id,
                  )
                }
              >

                <p class='' style={showMenu[navItem.id] ? { borderBottom: '3px solid #FD5900' } : { borderBottom: '3px solid #1A2B71' }}> {navItem.name}</p>
                {(navItem?.submenu?.length > 0) && <p><i className="bi bi-chevron-down"></i></p>}
              </div>
              {showMenu[navItem.id] && navItem?.submenu?.length > 0 && (
                <div className='NavbarSubMenuSection  p-2 py-4' onMouseEnter={() => setShowMenu({ [navItem.id]: true })} onMouseLeave={() => setShowMenu({ [navItem.id]: false })}>
                  <div className='position-relative bg-white row' style={{ width: '90%', left: '5%', overflowX: 'hidden', borderRadius: '0 25px 25px 25px' }}>
                    <div class='col-xl-8 col-7 row'>
                      {navItem?.submenu?.map((menu) => (
                        <>
                          {
                            menu?.is_bg_menu == 0 && menu?.is_active == '1' &&
                            <div className='col-xl-4 col-6 d-flex p-3   justify-content-between align-items-center' style={showSubMenu[menu.id] ? { borderRight: '1px solid #FD5900', color: '#FD5900', cursor: 'pointer' } : { borderRight: '1px solid #FD5900' }} key={menu.id} onMouseEnter={() => setShowSubMenu({ [menu.id]: true })} onMouseLeave={() => setShowSubMenu({ [menu.id]: false })}
                              onClick={() => menu?.url !== null ? navigate(`${menu?.url}`) :
                                moveInternalPage(
                                  menu?.url
                                )
                              }
                            >
                              <p>{menu.name} <span style={{ marginLeft: '5px' }}><FaArrowRightLong /></span> </p>
                            </div>
                          }
                        </>
                      ))}
                    </div>

                    <div class='col-xl-4 col-5 text-start mt-2'>
                      {navItem?.submenu?.map((btn) => (
                        <>
                          {
                            btn?.is_bg_menu != 0 && btn?.is_active == '1' &&
                            <div>
                              <button class='btn-orang my-2'
                                onClick={() => btn?.url !== null ? navigate(`${btn?.url}`) : moveInternalPage(
                                  btn?.url
                                )}>
                                {btn?.name}
                              </button>
                            </div>
                          }
                        </>

                      ))}
                    </div>

                  </div>
                </div>
              )}
            </React.Fragment>
          }
        </>
      );
    });
  };

  // mobile nav start  

  const mobileNavbar = () => {
    let arr = [];
    for (let i = 0; i < navData?.length; i++) {
      arr.push(
        <>
          {
            navData?.[i]?.is_active == "1" &&
            <>
              <div >
                <div key={i} class='d-flex justify-content-between p-1 align-items-center col-11 m-auto' onClick={() => navData?.[i]?.url !== null || "" ? moveMenuPage(`${navData?.[i]?.url}`) :
                  moveInternalPageMain(
                    navData?.[i]?.url
                  )
                }>
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <p >
                      {navData?.[i]?.name}
                    </p>
                  </div>
                  <div data-bs-toggle="collapse" href={`#navCollaps${navData?.[i]?.id}`} role="button" aria-expanded="false" aria-controls={`navCollaps${navData?.[i]?.id}`} data-bs-dismiss={navData?.[i]?.submenu?.length == 0 ? "offcanvas" : ""} aria-label={navData?.[i]?.submenu?.length == 0 ? "Close" : ""}>
                    {navData?.[i]?.submenu?.length > 0 && (
                      <span class='fs-4'>
                        {!idToCollapse[navData[i]?.name.split(" ").join("")] ||
                          collapse.hidden ==
                          idToCollapse[navData[i]?.name.split(" ").join("")] ? (
                          <i class="bi bi-plus p-2 fs-2 fw-bold"></i>
                        ) : (
                          <i class="bi bi-dash p-2"></i>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {
                navData?.[i]?.submenu?.length > 0 &&
                <div class="collapse col-11 m-auto" id={`navCollaps${navData?.[i]?.id}`}>
                  <div class="card card-body">
                    <div>
                      {navData?.[i]?.submenu?.map((menu) => (
                        <>
                          {
                            menu?.is_bg_menu == 0 && menu?.is_active == '1' &&
                            <div className='col-11 d-flex p-1  justify-content-between align-items-center' style={showSubMenu[menu.id] ? { color: '#FD5900', cursor: 'pointer' } : { color: 'black' }} key={menu.id} onMouseEnter={() => setShowSubMenu({ [menu.id]: true })} onMouseLeave={() => setShowSubMenu({ [menu.id]: false })} data-bs-dismiss="offcanvas" aria-label="Close"
                              onClick={() => menu?.url !== null ? navigate(`${menu?.url}`) : moveInternalPage(

                                menu?.url
                              )}>
                              <p>{menu.name} <span style={{ marginLeft: '5px' }}><FaArrowRightLong /></span> </p>
                            </div>
                          }
                        </>
                      ))}
                    </div>
                    <div>
                      {navData?.[i]?.submenu?.map((btn) => (
                        <>
                          {
                            btn?.is_bg_menu != 0 && btn?.is_active == '1' &&
                            <div class='text-start' data-bs-dismiss="offcanvas" aria-label="Close"
                              onClick={() => btn?.url !== null ? navigate(`${btn?.url}`) : moveInternalPage(
                                btn?.name
                                  ?.toLocaleLowerCase()
                                  .split(" ")
                                  .join("-"),
                                btn?.id,
                              )}>
                              <button class='btn-orang my-2'>
                                {btn?.name}
                              </button>
                            </div>
                          }
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              }
            </>
          }
        </>
      );
    }

    return arr;
  };










  return (
    <div class='navbarMain' style={{ height: 'auto', zIndex: '2' }}>
      <div class='windowNavbar'>
        <div class='' style={{ background: "#E8E8E8", color: '#1A2B71' }}>
          <div class='col-11 m-auto  d-flex align-items-center justify-content-between  p-1' >
            <div class='d-flex align-items-center gap-3 my-1'>
              {
                headerButton?.SocialIcon?.map((el) => {
                  return (
                    <a href={el.url} target='_blanck'>
                      <div style={{ width: '20px', height: '20px' }}>
                        <img src={el?.icon} style={{ width: '100%', height: '100%' }} alt="" />
                      </div>
                    </a>
                  )
                })
              }
              <p>{headerButton?.btn_detail?.time}</p>
            </div>
            <div class='d-flex align-items-center gap-3'>
              <p class="fw-semibold">Available in :</p>
              <p onClick={() => changeLanguage('en')} style={selectedLanguage == "en" ? { color: '#FD5900', fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' } : { color: '#1A2B71', fontSize: '14px', cursor: 'pointer' }}>English</p>
              <p onClick={() => changeLanguage('gu')} style={selectedLanguage == "gu" ? { color: '#FD5900', fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' } : { color: '#1A2B71', fontSize: '14px', cursor: 'pointer' }}>ગુજરાતી</p>
            </div>
          </div>
        </div>

        <div class='row mainNav'>
          <div class='d-flex align-items-center justify-content-between'>
            <div class='d-flex align-items-center'>
              <div class='d-flex align-items-center' style={{ height: '70px', backgroundImage: `url(${require('./images/navLeftBgLogo.png')})`, backgroundRepeat: "no-repeat", backgroundSize: '100% 100%' }}>
                <div class='d-flex align-items-center'>
                  <div class='mx-3' style={{ height: '60px', width: '60px' }}>
                    <img src={require('./images/navLeftLogo.png')} height={'100%'} style={{}} alt="" />
                  </div>
                  <div style={{ width: '100px' }}></div>
                </div>
              </div>

              {scroll &&

                <div class='d-flex gap-3 align-items-center m-0 p-0' style={{ height: '70px' }}>
                  {PcNavbar()}
                </div>


              }
            </div>
            {!scroll &&
              <div class='d-flex align-items-center'>
                <div>
                  <p class='fs-2 logoText' style={{ color: '#1A2B71' }}>The Kheda Dist. Central Co-op Bank LTD</p>
                  <img class='' src={require('./images/textLogo.png')} alt="" />
                  {/* <p class='fs-5  logoText' style={{ color: '#FD5900' }}>ખેડા જિલ્લા મધ્યસ્થ સહકારી બેંક લિમિટેડ</p> */}
                </div>

              </div>
            }

            <div class='d-flex align-items-center' style={{ height: '70px', backgroundImage: `url(${require('./images/navRightBgLogo.png')})`, backgroundRepeat: "no-repeat", backgroundSize: '100% 100%' }}>
              <div class='d-flex align-items-center'>
                <div style={{ width: '100px' }}></div>
                <div class='mx-3' style={{ height: '60px', width: '60px' }}>
                  <img src={require('./images/navRightLogo.png')} height={'100%'} style={{ borderRadius: '50%' }} alt="" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {!scroll &&
        <div class='' style={{ background: "#1A2B71" }}>
          <div class='windowNavbar bg-dar '>
            <div class="d-flex align-items-center justify-content-between ">
              <div class='d-flex gap-3 align-items-center ' style={{ height: '100%' }}>
                {PcNavbar()}
              </div>
              <div class='d-flex gap-5 align-items-center '>
                <div class=''>
                  <p class='ts-1 text-nowrap'>{headerButton?.btn_detail?.customer_care_desc}</p>
                  <p class='fw-bold ts-2 text-nowrap '>{headerButton?.btn_detail?.customer_care_number}</p>
                </div>
                <div class='d-flex gap-2' >
                  {
                    headerButton?.btn_detail?.btn1_name != "" &&
                    <a class='header1  d-flex align-items-center' href={headerButton?.btn_detail?.btn1_url} target='_blanck' > <span><img src={headerButton?.btn_detail?.btn1_img} style={{ width: '20px', height: '20px' }} alt="" /></span><span class='lh-1 fw-bold  header1Text' dangerouslySetInnerHTML={{ __html: headerButton?.btn_detail?.btn1_name }} ></span> </a>
                  }
                  {
                    headerButton?.btn_detail?.btn2_name != "" &&
                    <a class='header2  d-flex align-items-center ' href={headerButton?.btn_detail?.btn2_url} target='_blanck' ><span><img src={headerButton?.btn_detail?.btn2_img} style={{ width: '20px', height: '20px' }} alt="" /></span> <span class='lh-1 fw-bold  header2Text' dangerouslySetInnerHTML={{ __html: headerButton?.btn_detail?.btn2_name }}></span></a>
                  }
                  {
                    headerButton?.btn_detail?.btn3_name != "" &&
                    <a class='header1  d-flex align-items-center' href={headerButton?.btn_detail?.btn3_url} target='_blanck' > <span><img src={headerButton?.btn_detail?.btn3_img} style={{ width: '20px', height: '20px' }} alt="" /></span><span class='lh-1 fw-bold  header1Text' dangerouslySetInnerHTML={{ __html: headerButton?.btn_detail?.btn3_name }} ></span> </a>
                  }
                  <div class=''></div>
                </div>
              </div>
            </div>
          </div>



        </div>
      }

      <div class='mobileNavbar' style={{ background: '#1A2B71' }}>

        <div class='col-11 m-auto d-flex justify-content-between align-items-center text-white'>
          <div>
            <div class='my-2' style={{ height: '40px' }}>
              <img src={require('./images/mobileLogo.png')} height={'100%'} alt="" />
            </div>
          </div>
          <div>
            <div class='d-flex gap-2 my-3 align-items-center'>
              {
                headerButton?.btn_detail?.btn1_name != "" &&
                <a class='header1  d-flex align-items-center' href={headerButton?.btn_detail?.btn1_url} target='_blanck' > <span><img src={headerButton?.btn_detail?.btn1_img} style={{ width: '20px', height: '20px' }} alt="" /></span><span class='lh-1 fw-bold  header1Text' dangerouslySetInnerHTML={{ __html: headerButton?.btn_detail?.btn1_name }} ></span> </a>
              }
              {
                headerButton?.btn_detail?.btn2_name != "" &&
                <a class='header2  d-flex align-items-center ' href={headerButton?.btn_detail?.btn2_url} target='_blanck' ><span><img src={headerButton?.btn_detail?.btn2_img} style={{ width: '20px', height: '20px' }} alt="" /></span> <span class='lh-1 fw-bold  header2Text' dangerouslySetInnerHTML={{ __html: headerButton?.btn_detail?.btn2_name }}></span></a>
              }
              {
                headerButton?.btn_detail?.btn3_name != "" &&
                <a class='header1  d-flex align-items-center' href={headerButton?.btn_detail?.btn3_url} target='_blanck' > <span><img src={headerButton?.btn_detail?.btn3_img} style={{ width: '20px', height: '20px' }} alt="" /></span><span class='lh-1 fw-bold  header1Text' dangerouslySetInnerHTML={{ __html: headerButton?.btn_detail?.btn3_name }} ></span> </a>
              }

              <button class="btn  mobileTogal fs-3 p-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMobile" aria-controls="offcanvasMobile"  >
                <i class="bi bi-list text-white"></i>
              </button>
            </div>
          </div>

        </div>
        <div class="offcanvas offcanvas-end " tabindex="-1" id="offcanvasMobile" aria-labelledby="offcanvasMobileLabel" style={{ overflow: "hidden", transition: "1000ms", background: '#1a2b71', color: 'white', height: '100vh' }} >
          <div class="offcanvas-header p-0">

            <div class='d-flex  justify-content-between col-11 m-auto'>
              <div class='d-flex align-items-center gap-3'>
                <p class="fw-semibold">Available in :</p>
                <p onClick={() => changeLanguage('en')} style={selectedLanguage == "en" ? { color: '#FD5900', fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' } : { color: '#fff', fontSize: '14px', cursor: 'pointer' }}>English</p>
                <p onClick={() => changeLanguage('gu')} style={selectedLanguage == "gu" ? { color: '#FD5900', fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' } : { color: '#fff', fontSize: '14px', cursor: 'pointer' }}>ગુજરાતી</p>
              </div>
              <button type="button" class="btn  m-0 text-white" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="bi bi-x-circle fs-3"></i>
              </button>
            </div>
          </div>
          <div class="offcanvas-body p-0 mt-3">{mobileNavbar()}</div>
        </div>
      </div>



    </div>
  )
}

export default Navbar
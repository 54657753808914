import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { HiSelector } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { useLanguage } from "./LanguageContext";
import md5 from "md5";
import { API_BASE_URL } from "./config";

const DownloadForm = ({ lang }) => {
  const [data, setData] = useState();
  const [table, setTable] = useState();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [mainTable, setMainTable] = useState();
  const [order, setOrder] = useState("ASC");

  const { selectedLanguage } = useLanguage();

  const navigate = useNavigate()

  const sorting = (el) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[el].toLocaleLowerCase() > b[el].toLocaleLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[el].toLocaleLowerCase() < b[el].toLocaleLowerCase() ? 1 : -1
      );
      setData(sorted);
      setOrder("ASC");
    }
  };

  const sortingDate = (el) => {
    if (order === "ASC") {
      const sorted = [...data].sort(
        (a, b) =>
          a[el].toLocaleLowerCase().split("/").reverse().join("") -
          b[el].toLocaleLowerCase().split("/").reverse().join("")
      );
      setData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort(
        (a, b) =>
          b[el].toLocaleLowerCase().split("/").reverse().join("") -
          a[el].toLocaleLowerCase().split("/").reverse().join("")
      );
      setData(sorted);
      setOrder("ASC");
    }
  };

  const dis = [];

  for (let i = 0; i < data?.length; i++) {
    dis.push(data[i].category);
  }

  const uniq = dis.filter((item, index) => dis.indexOf(item) === index);

  const date = new Date()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  }
  const dateFomate = formatDate(date)
  const key = 'KDCC'
  const token = md5(dateFomate + key)

  useEffect(() => {

    axios.post(`${API_BASE_URL}/download-form-document`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
      .then((response) => {
        setData(response?.data?.data?.tBody);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `You need to provide valid credentials`,
        });

      });

  }, [selectedLanguage])


  const tBody = data
    ?.filter((el) => {
      if (filter.toLocaleLowerCase() == "") {
        return el;
      } else if (
        filter.toLocaleLowerCase() == el.category.toLocaleLowerCase()
      ) {
        return el;
      }
    })
    .filter((el) => {
      if (search == "") {
        return el;
      } else if (el.category.toLowerCase().includes(search.toLowerCase())) {
        return el;
      } else if (el.title.toLowerCase().includes(search.toLowerCase())) {
        return el;
      } else if (el.date.toLowerCase().includes(search.toLowerCase())) {
        return el;
      }
    })
    ?.map((el) => {
      return (
        <tr>
          <td>
            <p class="px-3 py-1 m-0">
              {el.title}{" "}
              {el.new == "New" ? (
                <span class="marquee_new mx-3 text-danger fw-bold">
                  {el.new}
                </span>
              ) : (
                ""
              )}
            </p>
          </td>
          <td>
            <p class="px-3 py-1 m-0">{el.date}</p>
          </td>
          <td>
            <p
              class="text-success px-3 m-0"
              style={{ cursor: "pointer", fontWeight: "500" }}
            >
              <a
                class="text-success"
                href={`${el.url}`}
                target="_blanck"
                download
              >
                Download
              </a>
            </p>
          </td>
        </tr>
      );
    });


  const description = (el) => {
    setFilter(el);
  };

  return (
    <div class="downloadForm">
      <Helmet>
        <meta charset="utf-8" />
        <title>{selectedLanguage == "en" ? "Download Circulars / Forms" : "પરિપત્ર/ફોર્મ ડાઉનલોડ કરો"}</title>
        <meta name="description" content={selectedLanguage == "en" ? "Download Circulars / Forms" : "પરિપત્ર/ફોર્મ ડાઉનલોડ કરો"} />
        <meta name="keywords" content={selectedLanguage == "en" ? "Download Circulars / Forms" : "પરિપત્ર/ફોર્મ ડાઉનલોડ કરો"} />
      </Helmet>
      <div style={{ background: '#ffefe6' }}>
        <br />
        <div>
          <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}> {selectedLanguage == "en" ? "Download Circulars / Forms" : "પરિપત્ર/ફોર્મ ડાઉનલોડ કરો"}</p>
        </div>
        <br />
      </div>
      <div class="row">
        <div class="col-md-3 col-11 p-3" style={{ margin: "0 auto" }}>
          <div
            class="text-start p-2 border"
            onClick={() => description("")}
            style={filter == "" ? { background: "#1a2b71", cursor: "pointer", color: 'white' } : { background: "#ffffff", cursor: "pointer", color: '#1a2b71' }}
          >
            <p class=" fw-bold m-0"> {selectedLanguage == "en" ? "All Category" : "તમામ શ્રેણી"}</p>
          </div>
          {uniq?.map((el) => {
            return (
              <div
                class="text-start p-2 border"
                onClick={() => description(`${el}`)}
                style={filter == el ? { background: "#1a2b71", cursor: "pointer", color: 'white' } : { background: "#ffffff", cursor: "pointer", color: '#1a2b71' }}
              >
                <p class="fw-semibold m-0">
                  {el}{" "}
                  {el.new == "New" ? (
                    <span class="marquee_new mx-3 text-danger fw-bold">
                      {el.new}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            );
          })}
        </div>
        <div class="col-md-9 col-11 p-3 " style={{ margin: "0 auto" }}>
          <input
            type="text"
            class="form-control"
            placeholder="Search here "
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => setSearch(e.target.value)}
          />

          <div
            class="mt-4"
            style={{ overflowX: "auto", height: "100%", overflowY: "hidden" }}
          >
            <table class="table " style={{ width: "100%" }} id="sortTable">
              <thead>
                <tr>
                  <th
                    style={{ background: "#f0f0f0" }}
                    onClick={() => sorting("title")}
                  >
                    <p class="px-3 m-0 ">
                      Description{" "}
                      <span>
                        <HiSelector />
                      </span>
                    </p>{" "}
                  </th>
                  <th
                    style={{ background: "#f0f0f0" }}
                    onClick={() => sortingDate("date")}
                  >
                    <p class="px-3 m-0 ">
                      Date{" "}
                      <span>
                        <HiSelector />
                      </span>
                    </p>{" "}
                  </th>
                  <th style={{ background: "#f0f0f0" }}>
                    <p class="px-3 m-0 ">Download</p>
                  </th>
                </tr>
              </thead>
              <tbody>{tBody}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadForm;



import React, { useEffect, useRef, useState } from 'react'
import { Achievement, BigSliderCarts, ExtraBigSliderCart, ImgTextOverLayCart, ImgTextOverLayCartSmall, SmallSliderCart, TopDaynamicSliderLarg, TopDaynamicSliderSmall } from './DaynamicReactMultyCarousel';
import './DaynamicPageStyle.css'
import { Link } from 'react-scroll';
import { useLanguage } from '../LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import md5 from 'md5';
import EmiCalculator from '../EmiCalculator';
import { Helmet } from 'react-helmet';
import { API_BASE_URL } from '../config';




const DaynamicPages = () => {

  const data = useParams()
  const { url } = useParams();
  const location = useLocation();
  const value = location?.state;
  const [getApiData, setGetApiData] = useState()
  const [homeData, setHomeData] = useState({})
  const { selectedLanguage } = useLanguage();
  const containerRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [option, setOption] = useState()
  const [headerSectionRendered, setHeaderSectionRendered] = useState(false);
  const navigate = useNavigate();

  console.log(url);

  const FilterText = ({ data }) => {
    const [textFilter, setTextFilter] = useState(data?.[0]?.title);
    const [filterData, setFilterData] = useState()
    useEffect(() => {
      setFilterData(data?.filter((el) => el?.title == textFilter))
    }, [textFilter])
    return (
      <div className='col-11 m-auto row align-items-center mt-4 gap-lg-0 gap-4'>
        <div className='col-lg-4'>
          {data.map((el, index) => (
            <div key={index} className='border-bottom border-3 col-11 mx-auto'>
              <p class='ts-4'
                style={{
                  color: textFilter === el.title ? '#FD5900' : '#1A2B71',
                }}
                onClick={() => setTextFilter(el.title)}  >
                <span>
                  {textFilter === el.title && <i className='bi bi-dot'></i>}
                </span>{' '}
                {el.title}
              </p>
            </div>
          ))}
        </div>
        <div class='col-lg-8'>
          <div class='col-11 p-2 m-auto' style={{ border: '2px solid #FD5900 ', borderColor: '#FD5900', borderRadius: '0px 25px 25px 25px', backgroundImage: `url(${filterData?.[0]?.image})`, backgroundSize: '100% 100%' }}>
            <br />
            <div class='col-11 m-auto'>
              <div class='col-lg-7 m-auto'>
                <p class='ts-4' style={{ color: '#FD5900' }} dangerouslySetInnerHTML={{ __html: filterData?.[0]?.text1 }}></p>
                <p class='ts-4' style={{ color: '#FD5900' }} dangerouslySetInnerHTML={{ __html: filterData?.[0]?.text2 }}></p>
                <div class='my-2 mt-3'>
                  {filterData?.[0]?.btn_nm != "" &&
                    <button class={`${filterData?.[0]?.btn_css} text-nowrap my-2`} onClick={() => navigate(`${filterData?.[0]?.url}`)} >{filterData?.[0]?.btn_nm} <i class="bi bi-arrow-right "></i></button>
                  }
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  const HomeMaltipalWay = (data) => {
    let arr = []
    for (let i = 0; i < data?.length; i++) {
      arr.push(
        <div class='daynamicMaltipalWay' data-aos="fade-in-up">
          <img src={data?.[i].image} style={{ width: '100%', height: '100%', objectFit: 'cover', }} alt="" />
          <div class='daynamicMaltipalWayText p-2'  >
            <div class='col-11 m-auto'>
              <p class='ts-3 fw-bold mt-xl-0 mt-1' dangerouslySetInnerHTML={{ __html: data?.[i]?.title }}></p>
              <p class='ts-2 lh-sm text-start daynamicMaltipalWayTextL' dangerouslySetInnerHTML={{ __html: data?.[i]?.desc }}></p>
              <div class='d-flex gap-3 my-3 '>
                {data?.[i]?.btn_nm != "" &&
                  <button class={`${data?.[i]?.btn_css} daynamicMaltipalWayTextL`} onClick={() => navigate(`${data?.[i]?.url}`)}  >{data?.[i]?.btn_nm} <i class="bi bi-arrow-right"></i></button>
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
    return arr;
  }


  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, [])

  // api 
  const date = new Date()
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  }
  const dateFomate = formatDate(date)
  const key = 'KDCC'
  const token = md5(dateFomate + key)
  const getData = () => {
    axios.post(`${API_BASE_URL}/custompage`, { lang: selectedLanguage, menu_type: "sub_menu", menu_id: url }, { headers: { 'X-Custom-Token': token } })
      .then((res) => {
        setGetApiData(res?.data?.data)
      })
      .catch((err) => {
        setGetApiData('')
      })
  }

  useEffect(() => {
    getData()
  }, [url, selectedLanguage, token])

  useEffect(() => {
    setHomeData(getApiData)
  }, [getApiData])

  // drage function 
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = x - startX;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setScrollLeft(containerRef.current.scrollLeft);
  };


  // filter text section 
  const section = homeData?.page_section;
  const sections = []
  const showElements = () => {
    let arr = [];
    for (const key in section) {
      sections.push(section[key]);
    }
    for (let i = 0; i < homeData?.header_btn?.length; i++) {
      let pageSectionId = homeData?.header_btn?.[i]?.pageId;
      let sectionType = homeData?.header_btn?.[i]?.sectionType;
      let pageSection = sections[i];
      // if (sectionType === "Slider") {
      //   arr.push(
      //     <>
      //       {homeData?.is_topslider_required == "Yes" &&
      //         <div id={pageSection?.pageId}>
      //           <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
      //           <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>
      //           <div class='topSliderWindow'>
      //             <TopDaynamicSliderLarg data={pageSection?.Data} />
      //           </div>
      //           <div class='topSliderLaptop'>
      //             <TopDaynamicSliderSmall data={pageSection?.Data} />
      //           </div>
      //         </div>
      //       }

      //     </>
      //   )
      // }
      if (sectionType === "More Slider") {
        arr.push(
          <>
            <div class='mt-4 col-11 rounded-3 m-auto' id={pageSection?.pageId}>
              <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
              <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>
              <div class='topSliderWindow'>
                <TopDaynamicSliderLarg data={pageSection?.Data} />
              </div>
              <div class='topSliderLaptop'>
                <TopDaynamicSliderSmall data={pageSection?.Data} />
              </div>
            </div>
          </>
        )
      } else if (sectionType === "SectionDetail") {
        arr.push(
          <div class='col-11 m-auto mt-3' id={pageSection?.pageId}>
            <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            <div style={{ overflowX: 'auto' }}>
              {pageSection?.Data?.map((el) => {
                return (
                  <p class='ts-2 mt-2 ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: el?.desc }}></p>
                )
              })}
            </div>
          </div>
        )
      } else if (sectionType === "ProductCards") {
        arr.push(
          <div class='mt-4' style={{ background: '#1a2b71' }} id={pageSection?.pageId}>
            <br />
            <div class='col-11 m-auto'>
              <p class='ts-6 fw-semibold' style={{ color: '#ffffff' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
              <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>
              <div class='my-3'>
                <BigSliderCarts data={pageSection?.Data} />
              </div>
            </div>
            <br />
          </div>
        )
      } else if (sectionType === "Eligibility") {
        arr.push(
          <div id={pageSection?.pageId}>
            <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>


            {
              pageSection?.Data?.map((el, i) => {
                return (
                  <div class='mt-5' key={i}>
                    <div class='col-11 m-auto rounded-5' style={{ backgroundImage: `url(${el?.image})`, backgroundSize: '100% 100%' }} >
                      <br />
                      <div class='col-11 m-auto'>
                        <p class='ts-6 col-lg-10 m-auto' style={{ color: '#ffffff' }} dangerouslySetInnerHTML={{ __html: el?.text1 }}></p>
                        <div class='mt-3'>
                          <p dangerouslySetInnerHTML={{ __html: el?.text2 }}></p>
                        </div>
                        <div class='my-2 mt-3'>
                          {el?.btn_nm != "" &&
                            <a style={{ textDecoration: 'none' }} class={`${el.btn_css} text-nowrap my-2`} href={el.url} target='_blank'    >{el?.btn_nm} <i class="bi bi-arrow-right "></i></a>
                          }
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      } else if (sectionType === "Offerings") {
        arr.push(
          <div id={pageSection?.pageId}>
            <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            {
              pageSection?.Data?.map((el, i) => {
                return (
                  <div class='mt-5' key={i}>
                    <div class='col-11 m-auto rounded-5' style={{ backgroundImage: `url(${el?.image})`, backgroundSize: 'cover' }} >

                      <div class='col-11 m-auto row'>
                        <div class='col-md-8 text-start d-flex  align-items-center'>
                          <div class=''>
                            <p class='ts-6  ' style={{ color: '#ffffff' }} dangerouslySetInnerHTML={{ __html: el?.text1 }}></p>
                            <div class='mt-3'>
                              <p class='ts-2 text-white' dangerouslySetInnerHTML={{ __html: el?.text2 }}></p>
                            </div>
                            <div class='my-2 mt-3'>
                              {el?.btn_nm != "" &&
                                <a style={{ textDecoration: 'none' }} class={`${el.btn_css} text-nowrap my-2`} href={el.url} target='_blank'    >{el?.btn_nm} <i class="bi bi-arrow-right "></i></a>
                                // <button class={`${el.btn_css} text-nowrap my-2`} onClick={() => navigate(`${el.url}`)} >{el?.btn_nm} <i class="bi bi-arrow-right "></i></button>
                              }
                            </div>
                          </div>
                        </div>
                        <div class='col-md-4'>
                          <div class='col-sm-11 m-auto'>
                            <img src={el.image2} style={{ width: '100%' }} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      } else if (sectionType === "StepApply") {
        arr.push(
          <div id={pageSection?.pageId}>
            <br />
            <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>
            <FilterText data={pageSection?.Data} />
          </div>
        )
      } else if (sectionType === "Faq") {
        arr.push(
          <div class='mt-4 col-11 m-auto' id={pageSection?.pageId}>
            <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            <div className="accordion" id="categoryAccordion">
              {
                pageSection?.Data?.map((el, i) => {
                  return (
                    <div key={i} class='accordion-item mt-4 rounded-3'>
                      <button
                        className="accordion-button rounded-3"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#category${i}`}
                        aria-expanded="true"
                        aria-controls={`category${i}`}
                      >

                        <div class='d-flex align-items-center  justify-content-between' >
                          <div>
                            <p class='ts-3'><span>{i + 1}.</span> <span class='mx-2' dangerouslySetInnerHTML={{ __html: el?.question }}></span></p>
                          </div>
                        </div>
                      </button>
                      <div className="accordion-collapse collapse" aria-labelledby={`heading${i}`} data-bs-parent="#categoryAccordion" id={`category${i}`}>
                        <div class="card card-body border-0">
                          <p class='ts-2 tjustify' dangerouslySetInnerHTML={{ __html: el?.ans }}></p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>
          </div>
        )
      } else if (sectionType === "CustomerSay") {
        arr.push(
          <div class='mt-4 col-11 m-auto ' id={pageSection?.pageId}>
            <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            <div class='mt-4'>
              <ExtraBigSliderCart data={pageSection?.Data} />
            </div>
          </div>
        )
      } else if (sectionType === "Magazine") {
        arr.push(
          <div class='row mt-5' id={pageSection?.pageId} style={{ backgroundImage: 'linear-gradient( #ffffff 20% ,#ffefe6 0% )' }}>
            <div class='col-md-6'>
              <div class='col-11'>
                <img class='mb-3' data-aos="zoom-in-right" src={pageSection?.Data?.[0]?.image} style={{ width: '100%' }} alt="" />
              </div>
            </div>
            <div class='col-md-6'>
              <div class='d-flex align-items-end h-100  '>
                <div class='col-11 m-auto text-start '  >
                  <p class='ts-7 fw-bold mb-2' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
                  <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>
                  {
                    pageSection?.Data?.[0]?.btn1_nm != "" &&
                    <button data-aos="fade-right" class={pageSection?.Data?.[0]?.btn1_css} onClick={() => navigate(`${pageSection?.Data?.[0]?.url1}`)}  >{pageSection?.Data?.[0]?.btn1_nm} <i class="bi bi-arrow-right"></i></button>
                  }
                  <p class='ts-5 fw-bold mt-2' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: pageSection?.Data?.[0]?.desc }}></p>
                  {pageSection?.Data?.[0]?.btn2_nm != "" &&
                    <button data-aos="fade-right" class={pageSection?.Data?.[0]?.btn2_css} onClick={() => navigate(`${pageSection?.Data?.[0]?.url2}`)}  >{pageSection?.Data?.[0]?.btn2_nm} <i class="bi bi-arrow-right"></i></button>
                  }

                </div>
              </div>
              <br /><br />
            </div>
          </div>
        )
      } else if (sectionType === "Features") {
        arr.push(
          <div class='mt-4' id={pageSection?.pageId} style={{ background: '#1A2B71' }}>
            <br />
            <div class='col-11 m-auto '>
              <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
              <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

              <div class='my-3'>
                <SmallSliderCart data={pageSection?.Data} />
              </div>
            </div>
            <br />
          </div>
        )
      } else if (sectionType === "Insurance") {
        arr.push(
          <div class='mt-4' id={pageSection?.pageId}  >
            <p class='ts-6 fw-bold' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            <div class='col-11 m-auto mt-3 row'>
              {
                pageSection?.Data?.map((el) => {
                  return (
                    <div class='col-lg-4 col-md-6 mt-4'>
                      <div class='col-11 m-auto text-start' style={{ borderRadius: '0px 25px 25px 25px', overflow: 'hidden', border: '1px solid #FD5900' }}>
                        <div style={{ height: '250px', objectFit: 'cover' }}>
                          <img src={el?.image} style={{ height: '100%', width: '100%', objectFit: 'cover' }} alt="" />
                        </div>
                        <div class='px-3'>
                          <p class='ts-4 fw-bold py-2' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                          <p class='ts-2' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: el?.desc }}></p>

                          <div class=' d-flex gap-sm-3 align-items-center my-2 mt-3  justify-content-start flex-wrap '>
                            {
                              el?.btn1_nm != "" &&
                              <button class={`${el.btn1_css} text-nowrap  my-1`} onClick={() => navigate(`${el.url1}`)}>{el?.btn1_nm} <i class="bi bi-arrow-right "></i></button>
                            }
                            {
                              el?.btn2_nm != "" &&
                              <button class={`${el.btn2_css} text-nowrap my-1`} onClick={() => navigate(`${el.url2}`)}>{el?.btn2_nm} <i class="bi bi-arrow-right "></i></button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      } else if (sectionType === "RelatedProducts") {
        arr.push(
          <div class='mt-4' id={pageSection?.pageId} style={{ background: 'linear-gradient(#fFEFE6 60%, #ffffff 0)' }}  >
            <br />
            <p class='ts-6 fw-bold ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            <div class='mt-4 col-11 m-auto'>
              <ImgTextOverLayCartSmall data={HomeMaltipalWay(pageSection?.Data)} />
            </div>
            <br />

          </div>
        )
      } else if (sectionType === "Advantages") {
        arr.push(
          <div class='mt-4' id={pageSection?.pageId}  >

            <p class='ts-6 fw-bold ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            <div class='mt-4 col-11 m-auto d-flex flex-wrap  align-content-center justify-content-center'>
              {pageSection?.Data?.map((el) => {
                return (

                  <div class='d-grid align-items-center justify-content-center p-3' style={{ maxWidth: '350px', border: '1px solid transparent', borderImage: `url(${require('../images/borderLine.png')})30 stretch ` }}>
                    <div>
                      <img src={el?.image} class='img-fluid' alt="" />
                    </div>
                    <div class='col-8 m-auto mt-3'>
                      <p class='ts-3 fw-bold ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: el?.name }}></p>
                    </div>
                    <div class='col-11 m-auto'>
                      <p class='ts-1' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: el?.desc }}></p>
                    </div>
                  </div>
                )
              })}
            </div>


            <br />

          </div>
        )
      } else if (sectionType === "VisionSolution") {
        arr.push(
          <div class='mt-4' id={pageSection?.pageId}  >
            <p class='ts-6 fw-bold ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            {
              pageSection?.Data?.map((el) => {
                return (
                  <div class='row '>
                    <div class='col-md-6 p-0 h-100'>
                      <img src={el?.image} style={{ width: '100%' }} alt="" />
                    </div>
                    <div class='col-md-6 d-flex align-items-center' style={{ background: "#ffefe6" }}>
                      <div class='col-11 m-auto text-start my-3'>
                        <p class='ts-6 fw-bold ' style={{ color: '#FD5900' }} dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                        <p class='ts-2 ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: el?.desc }}></p>
                        {
                          el?.btn1_nm !== '' &&
                          <button class={`${el.btn1_css} text-nowrap my-2`} onClick={() => navigate(`${el.url1}`)} >{el?.btn1_nm} <i class="bi bi-arrow-right "></i></button>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
            <br />

          </div>
        )
      } else if (sectionType === "Market") {
        arr.push(
          <div class='mt-4' id={pageSection?.pageId}  >
            <p class='ts-6 fw-bold col-11 m-auto ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            {
              pageSection?.Data?.map((el) => {
                return (
                  <div class='mt-5 d-flex align-items-center' style={{ width: '100%', height: '100vh', backgroundImage: `url(${el?.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                    <div class=' col-lg-6 col-md-8  col-11 rounded-end-4 my-5' data-aos="fade-right" style={{ background: 'rgba(29,46,66,.9)' }}>
                      <br />
                      <div class='col-11  mt-lg-5 mt-3 text-white' data-aos="fade-right" style={{ clipPath: 'polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%)', background: '#FD5900' }}>
                        <div class='col-10 m-auto p-1 text-start '>
                          <p class='ts-4 fw-bold ' dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                        </div>
                      </div>
                      <div class='col-11 mt-4'>
                        <div class='col-10 text-start text-white m-auto fw-blod'>
                          <div class='mt-2 ts-2' data-aos="fade-right" dangerouslySetInnerHTML={{ __html: el?.desc }}></div>
                          <br />
                          <div class='mb-lg-5 mb-3'>
                            {
                              el?.btn1_nm != "" &&
                              <button data-aos="fade-right" class={el.btn1_css} onClick={() => navigate(`${el.url1}`)} >{el?.btn1_nm} <i class="bi bi-arrow-right"></i></button>
                            }
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      } else if (sectionType === "NeedAssisstance") {
        arr.push(
          <div class='mt-4' style={{ background: '#1A2B71' }} id={pageSection?.pageId}  >
            <br />
            <p class='ts-6 fw-bold ' style={{ color: '#ffffff' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>

            <div class='mt-4 col-11 m-auto row align-content-center justify-content-xl-center justify-content-start'>
              {pageSection?.Data?.map((el) => {
                return (

                  <div class='col-xl-4 col-lg-6  '  >
                    <div class=' col-11 m-auto  d-flex align-items-center gap-3 p-3'>
                      <div>
                        <img src={el?.image} class='img-fluid' alt="" />
                      </div>
                      <div class='text-start'>
                        <p class='ts-2 fw-bold ' style={{ color: '#ffffff' }} dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                        <p class='ts-3 fw-semibold ' style={{ color: '#ffffff' }} dangerouslySetInnerHTML={{ __html: el?.text }}></p>

                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      } else if (sectionType === "Achievement") {
        arr.push(
          <div class='mt-4' id={pageSection?.pageId}  >
            <p class='ts-6 fw-bold ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>
            <div class='mt-3'>
              <Achievement data={pageSection?.Data} />
            </div>
            <br />
          </div>
        )
      } else if (sectionType === "Calculator") {
        arr.push(
          <div class='' id={pageSection?.pageId}  >
            <p class='ts-6 fw-bold ' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.title }}></p>
            <p class='' style={{ color: '#1A2B71' }} dangerouslySetInnerHTML={{ __html: pageSection?.otherText }}></p>
            <div class='mt-3'>
              <EmiCalculator />
            </div>
            <br />
          </div>
        )
      }
    }
    return arr
  }
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>{homeData?.page_title}</title>
        <meta name="description" content={homeData?.page_desc} />
        <meta name="keywords" content={homeData?.page_keyword} />
      </Helmet>
      {
        homeData !== "" &&
        <div>
          {
            homeData?.is_topslider_required == "Yes" ?
              <div >
                <div class='topSliderWindow'>
                  <TopDaynamicSliderLarg data={homeData?.main_slider} />
                </div>
                <div class='topSliderLaptop'>
                  <TopDaynamicSliderSmall data={homeData?.main_slider} />
                </div>
              </div>
              :
              <div style={{ background: '#ffefe6' }}  >
                <br />
                <div>
                  <p class='ts-7 fw-bold' style={{ color: '#FD5900' }}>{homeData?.page_title}</p>
                </div>
                <br />
              </div>
          }

          {
            homeData?.is_header_required == "Yes" &&
            <div style={{ background: '#ffefe6' }} >
              <div class='col-11 m-auto d-flex gap-sm-5 gap-4 justify-content-xl-around ' style={{ overflowX: 'auto', cursor: 'grab' }} ref={containerRef} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
                {homeData?.header_btn?.map((el) => {
                  return (
                    <>
                      {el?.buttonName == "" ? '' :
                        <Link
                          activeClass="active"
                          to={el.pageId}
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >

                          <div class='mt-2' key={el?.pageId} style={option == el.pageId ? { borderBottom: '3px solid #FD5900', width: 'fit-content' } : { borderBottom: '3px solid #ffefe6', width: 'fit-content' }} onClick={() => setOption(el.pageId)} >
                            <div class='d-flex align-items-center gap-2'>
                              <div>
                                <p class='fw-bold my-2 text-nowrap ts-2' style={option == el.pageId ? { color: "#FD5900" } : { color: 'black' }}  >{el.menuName}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      }
                    </>
                  )
                })
                }


              </div>
            </div>
          }

          <div >
            {showElements()}
          </div>
        </div>
      }

      {
        homeData == "" &&
        <div class='d-flex col-11 m-auto align-items-center justify-content-center' style={{}}>
          <div class='col-lg-6 col-md-8 col-11 m-auto'>
            <div class='col-sm-11 m-auto'>
              <div>
                <img src={require('../images/404.webp')} alt="" width='100%' />
              </div>
            </div>
            <div>
              <button onClick={() => navigate('/')} class='btn-blue'>{selectedLanguage == "en" ? "Go to Home page" : "હોમ પેજ પર જાઓ"}<span><i class="bi bi-arrow-right "></i></span> </button>
              {/* <p class='fs-1 '>{selectedLanguage == "en" ? "Page Not Found '404'" : "પૃષ્ઠ '404' મળ્યું નથી"}</p> */}
            </div>
          </div>

        </div>
      }

    </div>
  )
}

export default DaynamicPages
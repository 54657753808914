import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


export const HomeTop = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 990 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 990, min: 660 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 660, min: 420 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 420, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class="">
            {el}
          </div>
        )
      })}
    </Carousel>
  );
}


export const HomeMultipalWay = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1400 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1400, min: 990 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 990, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={true}
      autoPlaySpeed={8000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class=" col-lg-10 col-sm-11 m-auto ">
            {el}
          </div>
        )
      })}
    </Carousel>
  );
}


export const TopDaynamicSlider = ({ data }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 990 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 990, min: 660 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 660, min: 420 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 420, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      arrows={false}
      autoPlaySpeed={5000}
      showDots={false}
    >
      {data?.map((el) => {
        return (
          <div class="">
            {el}
          </div>
        )
      })}
    </Carousel>
  );
} 
import React from "react";
import { useState } from "react";
import HomeEmi from "./emi/HomeEmi";
import FixeDeposit from "./emi/FixeDeposit";
import RecurringDeposit from "./emi/RecurringDeposit";
import { useLanguage } from "./LanguageContext";


const EmiCalculator = () => {
  const [amount, setAmount] = useState("100000");
  const [intrest, setIntrest] = useState("5");
  const [year, setYear] = useState("2");
  const [btnYearMonth, setBtnYearMonth] = useState("year");
  const [section, setSection] = useState("loan");
  const { selectedLanguage } = useLanguage();


  return (
    <div class="customer" style={{ background: '#FFEFE6' }}>

      <div class="row">
        <div class="col-md-12 col-12 px-sm-5 ">
          {/* <div class="text-start p-2">
            <p class="fw-semibold mb-0" style={{ fontSize: "30px" }}>
              {selectedLanguage == "en" ? 'EMI-Calculator' : 'EMI-કેલ્ક્યુલેટર'}
            </p>
          </div> */}
          <div>
            {/* <div class="p-2  d-flex gap-2 flex-wrap" style={{background:'#1A2B71'}}>
              <button class={section  == "loan" ? 'btn  btn-light p-1 px-3 fw-bold': 'btn btn-outline-light p-1 px-3 fw-bold'} onClick={() => setSection("loan")}>
              {selectedLanguage == "en" ? 'Loan' : 'લોન'}  
              </button>
              <button class={section  == "FixedDeposit" ? 'btn  btn-light p-1 px-3 fw-bold': 'btn btn-outline-light p-1 px-3 fw-bold'} onClick={() => setSection("FixedDeposit")}>
              {selectedLanguage == "en" ? 'Fixed Deposit (FD)' : 'ફિક્સ્ડ ડિપોઝિટ (FD)'}  
              </button>
              <button class={section  == "RecurringDeposit" ? 'btn  btn-light p-1 px-3 fw-bold': 'btn btn-outline-light p-1 px-3 fw-bold'} onClick={() => setSection("RecurringDeposit")}>
              {selectedLanguage == "en" ? ' Recurring Deposit (RD)' : 'રિકરિંગ ડિપોઝિટ (RD)'}  
              </button>
            </div> */}

            <div class="p-2  d-flex gap-2 flex-wrap col-11 m-auto mt-4" style={{ background: '' }}>
              <button class='btn-orang' onClick={() => setSection("loan")}>
                {selectedLanguage == "en" ? 'Loan' : 'લોન'}
              </button>
              <button class='btn-orang' onClick={() => setSection("FixedDeposit")}>
                {selectedLanguage == "en" ? 'Fixed Deposit (FD)' : 'ફિક્સ્ડ ડિપોઝિટ (FD)'}
              </button>
              <button class='btn-orang' onClick={() => setSection("RecurringDeposit")}>
                {selectedLanguage == "en" ? ' Recurring Deposit (RD)' : 'રિકરિંગ ડિપોઝિટ (RD)'}
              </button>
            </div>
            <div class='mt-4'>
              {section == "loan" ? <HomeEmi /> : ""}
              {section == "FixedDeposit" ? <FixeDeposit /> : ""}
              {section == "RecurringDeposit" ? <RecurringDeposit /> : ""}
            </div><br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmiCalculator;

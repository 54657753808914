import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLanguage } from './LanguageContext';
import md5 from 'md5';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config';

const Footer = () => {
    const [footerData, setFooterData] = useState()
    const { selectedLanguage, changeLanguage } = useLanguage();



    const date = new Date()
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}${month}${day}`;
    }

    const dateFomate = formatDate(date)
    const key = 'KDCC'
    const token = md5(dateFomate + key)

    useEffect(() => {
        axios.post(`${API_BASE_URL}/footerdata`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
            .then((res) => {
                setFooterData(res?.data?.data)
            })
            .catch((err) => {
                // console.log(err);  
            })
    }, [selectedLanguage, token])


    const navigate = useNavigate()




    return (
        <div>
            <div class='d-flex align-items-end justify-content-center'>
                <div>
                    <p class='ts-6 mt-3' style={{ color: '#1A2B71' }}>{footerData?.footer_downloadapp?.title}</p>
                    <div class='d-flex gap-4 flex-wrap align-items-center justify-content-center mt-2'>
                        {
                            footerData?.footer_downloadapp?.btn1_img !== "" &&
                            <div class=' ' style={{ height: '70px' }}>
                                <a target='_blank' href={footerData?.footer_downloadapp?.btn1_url}>
                                    <img src={footerData?.footer_downloadapp?.btn1_img} height={'100%'} alt="" />
                                </a>
                            </div>
                        }

                        {
                            footerData?.footer_downloadapp?.btn2_img !== "" &&
                            <div class=' ' style={{ height: '70px' }}>
                                <a target='_blank' href={footerData?.footer_downloadapp?.btn2_url}>
                                    <img src={footerData?.footer_downloadapp?.btn2_img} height={'100%'} alt="" />
                                </a>
                            </div>
                        }

                        {
                            footerData?.footer_downloadapp?.btn3_img !== "" &&
                            <div class=' ' style={{ height: '70px' }}>
                                <a target='_blank' href={footerData?.footer_downloadapp?.btn3_url}>
                                    <img src={footerData?.footer_downloadapp?.btn3_img} height={'100%'} alt="" />
                                </a>
                            </div>
                        }

                        {
                            footerData?.footer_downloadapp?.btn4_img !== "" &&
                            <div class=' ' style={{ height: '70px' }}>
                                <a target='_blank' href={footerData?.footer_downloadapp?.btn4_url}>
                                    <img src={footerData?.footer_downloadapp?.btn4_img} height={'100%'} alt="" />
                                </a>
                            </div>
                        }


                    </div>
                </div>
            </div>
            <br />

            <div class='bg-black'>
                <br />
                <div class='col-11 m-auto row text-white text-start'>
                    <div class='col-lg-9 row '>
                        {
                            footerData?.footer_menu?.map((el) => {
                                return (
                                    <div class='col-md-3 col-6' >
                                        <p class='fw-bold ts-2 mt-3' onClick={() => navigate(el?.url)} style={{ cursor: 'pointer' }}>{el?.name}</p>
                                        {
                                            el?.submenu?.map((e) => {
                                                return (
                                                    <p class='ts-1 mt-2 text-secondary fw-semibold ' style={{ cursor: 'pointer' }} onClick={() => navigate(e?.url)}>{e?.name}</p>
                                                )
                                            })
                                        }
                                        {/* <p class='ts-1 mt-2 text-secondary fw-semibold'>Contact</p>
                                        <p class='ts-1 mt-2 text-secondary fw-semibold'>Branch</p>
                                        <p class='ts-1 mt-2 text-secondary fw-semibold'>About US</p>
                                        <p class='ts-1 mt-2 text-secondary fw-semibold'>Download</p>
                                        <p class='ts-1 mt-2 text-secondary fw-semibold'>Tender Documents</p> */}
                                    </div>
                                )
                            })
                        }
                        {/* <div class='col-md-3 col-6'>
                            <p class='fw-bold ts-2 mt-3'>OTHER SERVICES</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Loans</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>ATM Service</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Deposit Schemes</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Lockers Service </p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>RuPay Debit Card</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>RTGS/NEFT/IMPS </p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Loan Application Form </p>

                        </div>
                        <div class='col-md-3 col-6'>
                            <p class='fw-bold  ts-2 mt-3'>MORE</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Tender </p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>EMI Calculater</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Customer Grivance</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Interest Rates</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Service Charges</p>
                            <p class='ts-1 mt-2 text-secondary fw-semibold'>Official login Documents</p>
                        </div> */}
                        <div class='col-md-3 col-6'>
                            <p class='fw-bold mt-3 ts-2' dangerouslySetInnerHTML={{ __html: footerData?.footer_detail?.title1 }}></p>
                            <p class='mt-2 text-secondary fw-semibold ts-1' dangerouslySetInnerHTML={{ __html: footerData?.footer_detail?.desc1 }}></p>
                            <p class='fw-bold fs-5 mt-3' dangerouslySetInnerHTML={{ __html: footerData?.footer_socialicon?.title }}></p>
                            <div class='d-flex gap-3 align-items-center mt-2 fs-4'>
                                {
                                    footerData?.footer_socialicon?.IconDetail?.map((el) => {
                                        return (

                                            <a href={el.url} target='_blanck' style={{ width: '20px', height: "20px" }}>
                                                <img src={el?.icon} style={{ width: "100%", aspectRatio: "1/1" }} alt="" />
                                            </a>
                                        )
                                    })
                                }
                                {/* <p><i class="bi bi-facebook"></i></p>
                                <p><i class="bi bi-instagram"></i></p>
                                <p><i class="bi bi-youtube"></i></p>
                                <p><i class="bi bi-twitter-x"></i></p> */}
                            </div>


                        </div>
                    </div>
                    <div class='col-lg-3 col-sm-6 row   p-0 m-0'>

                        <div class=''>
                            <p class='fw-bold ts-2 mt-3' dangerouslySetInnerHTML={{ __html: footerData?.footer_detail?.title2 }}></p>
                            <p class='mt-2 text-secondary fw-semibold ts-1' dangerouslySetInnerHTML={{ __html: footerData?.footer_detail?.desc2 }}></p>
                            <div class='row mt-3'>
                                <div class='col-4'>
                                    <div class='col-11'>
                                        <a href={footerData?.footer_dicgc?.btn1_url} target='_blank'>
                                            <img src={footerData?.footer_dicgc?.btn1_img} style={{ width: '100%', borderRadius: '20px', cursor: 'pointer' }} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div class='col-4'>
                                    <div class='col-11'>
                                        <a href={footerData?.footer_dicgc?.btn2_url} target='_blank'>
                                            <img src={footerData?.footer_dicgc?.btn2_img} style={{ width: '100%', borderRadius: '20px', cursor: 'pointer' }} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div class='col-4'>
                                    <div class='col-11'>
                                        <a href={footerData?.footer_dicgc?.btn3_url} target='_blank'>
                                            <img src={footerData?.footer_dicgc?.btn3_img} style={{ width: '100%', borderRadius: '20px', cursor: 'pointer' }} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div class='col-11 m-auto'>
                                    <p class='ts-1 mt-2 text-secondary fw-semibold text-center' dangerouslySetInnerHTML={{ __html: footerData?.footer_dicgc?.desc }}></p>

                                </div>
                            </div>



                        </div>

                    </div>
                </div>
                <div class='d-flex align-items-center text-secondary ts-1 col-11 m-auto text-start mt-3 fw-bold'>
                    <p dangerouslySetInnerHTML={{ __html: footerData?.footer_detail?.copyright }}></p>
                </div>
                <br />
            </div>
        </div>
    )
}

export default Footer
import './App.css';
import Navbar from './components/Navbar';
import { LanguageProvider, useLanguage } from './components/LanguageContext';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import React, { Suspense, useEffect } from 'react';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './components/NotFound ';
import Home from './components/Home';
import Gallery from './components/Gallery';
import DaynamicPages from './components/DaynamicPages/DaynamicPages';
import Magazine from './components/Magazine';
import ETender from './components/ETender';
import BranchLocater from './components/BranchLocater';
import ApplyNow from './components/ApplyNow';
import ApplyNowStatus from './components/ApplyNowStatus';
import QueryNow from './components/QueryNow';
import QueryNowStatus from './components/QueryNowStatus';
import QukiLink from './components/QukiLink';
import DownloadForm from './components/DownloadForm';
import UnclaimedDeposit from './components/UnclaimedDeposit';
import InoperativeAccounts from './components/InoperativeAccounts';

function App() {
  const { selectedLanguage } = useLanguage();
  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])


  return (
    <div className={selectedLanguage == "en" ? 'App poppins' : 'App anekGujarati'}>
      <ScrollToTop />
      <Navbar />
      <QukiLink />
      <div class='headerBottom'></div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path={'/:url'} element={<DaynamicPages />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/magazine' element={<Magazine />} />
        <Route path='/e-tender' element={<ETender />} />
        <Route path='/branch-locater' element={<BranchLocater />} />
        <Route path='/apply-now' element={<ApplyNow />} />
        <Route path='/apply-now-status' element={<ApplyNowStatus />} />
        <Route path='/query-now' element={<QueryNow />} />
        <Route path='/query-now-status' element={<QueryNowStatus />} />
        <Route path='/download-form' element={<DownloadForm />} />
        <Route path='/inoperative-accounts' element={<InoperativeAccounts />} />
        <Route path='/unclaimed-deposit' element={<UnclaimedDeposit />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
